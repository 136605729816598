import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { CompanyPageLayout } from '../components/companyPageLayout';
import { ContactUsForm } from '../components/contactUsBoltFarm';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <CompanyPageLayout title="TRYBE x Bolt Farm">{children}</CompanyPageLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="max-w-xl mx-auto">
  <ContactUsForm mdxType="ContactUsForm" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      